import request from '@/utils/request2'

// 获取当天待面试人数
export function getZpTotal (params) {
  const { empid } = params
  return request({
    url: '/api/wxzp/getZpTotal',
    method: 'get',
    params: { empid }
  })
}

// 获取当月有面试的日期列表
export function getHasIVDateList (params) {
  const { empid, yymm } = params
  return request({
    url: '/api/wxzp/getHasIVDateList',
    method: 'get',
    params: { empid, yymm }
  })
}

// 获取某天需要参加面试的详细人员名单列表
export function getIvList (params) {
  const { empid, yymmdd } = params
  return request({
    url: '/api/wxzp/getIvList',
    method: 'get',
    params: { empid, yymmdd }
  })
}

// 获取某个应聘者的详细简历信息
export function getResumeDetail (data) {
  // const { autoid } = params
  return request({
    url: '/api/wxzp/getResumeDetail',
    method: 'get',
    params: data
  })
}

// 保存面试评价信息
export function ivSetView (params) {
  const { autoid, iv_res, iv_pj, iv_outreason, remark, username, pjdetail } = params
  return request({
    url: '/api/wxzp/ivSetView',
    method: 'post',
    data: {
      autoid,
      iv_res,
      iv_pj,
      iv_outreason,
      remark,
      username,
      pjdetail
    },
    headers: { "Content-Type": "multipart/form-datacharset=UTF-8" }
  })
}

// 获取面试测评问卷信息
export function getIvQtOptionList (params) {
  const { stationid } = params
  return request({
    url: '/api/wxzp/getIvQtOptionList',
    method: 'get',
    params: {
      stationid
    }
  })
}

// 保存面试测评问卷信息
export function saveIvQtOptionList (data) {
  return request({
    url: '/api/wxzp/saveIvQtOptionList',
    method: 'post',
    data: data
  })
}

// 获取待入职人员列表接口
export function getEntryList (params) {
  const { empid } = params
  return request({
    url: '/api/wxzp/getEntryList',
    method: 'get',
    params: { empid }
  })
}

// 确认入职接口
export function confirmEntry (params) {
  const { autoid, entrydate, ischange_empcode, empcode, isautocode, username } = params
  return request({
    url: '/api/wxzp/confirmEntry',
    method: 'get',
    params: { autoid, entrydate, ischange_empcode, empcode, isautocode, username }
  })
}

// 确认入职接口
export function dropEntry (params) {
  const { autoid, notentryreason, username } = params
  return request({
    url: '/api/wxzp/dropEntry',
    method: 'get',
    params: { autoid, notentryreason, username }
  })
}

// 微信端获取待面试确认的简历明细接口
export function getIvCheckList (params) {
  const { empid } = params
  return request({
    url: '/api/wxzp/getIvCheckList',
    method: 'get',
    params: { empid }
  })
}

// 同意面试接口
export function checkIv (params) {
  return request({
    url: '/api/wxzp/checkIv',
    method: 'get',
    params: params
  })
}

// 不同意面试接口
export function unCheckIv (params) {
  const { autoid, reason, username } = params
  return request({
    url: '/api/wxzp/unCheckIv',
    method: 'get',
    params: { autoid, reason, username }
  })
}

// 获取面试评价方案项目明细
export function getPjDetail (params) {
  const { pjid } = params
  return request({
    url: '/api/wxzp/getPjDetail',
    method: 'get',
    params: { iv_autoid: pjid }
  })
}

// 获取待面试个人信息的附件列表
export function getModuleAttFileList (params) {
  const { moduleno, mautoid, username } = params
  return request({
    url: '/api/wx/getModuleAttFileList',
    method: 'get',
    params: { moduleno, mautoid, username }
  })
}

// 检测附件是否存在  
export function checkFileExists (data) {
  const { moduleno, dflag, autoid, filename, fileext, downloadpath } = data
  return request({
    url: '/api/wx/checkFileExists',
    method: 'post',
    data: { moduleno, dflag, autoid, filename, fileext, downloadpath },
    headers: { "Content-Type": "multipart/form-datacharset=UTF-8" }
  })
}

// 下载附件
export function getDownLoadFileName (data) {
  const { dflag, moduleno, autoid, filename, fileext, downloadpath } = data
  return request({
    url: '/api/wx/getDownLoadFileName',
    method: 'post',
    data: { dflag, moduleno, autoid, filename, fileext, downloadpath },
    headers: { "Content-Type": "multipart/form-datacharset=UTF-8" }
  })
}

// 获取待处理预转正列表
export function getZzCheckList (params) {
  const { empid } = params
  return request({
    url: '/api/wxzp/getZzCheckList',
    method: 'get',
    params: { empid }
  })
}

// 保存预转正结果接口
export function saveZzInfo (params) {
  const { autoid, checktype, confirmdate, checkreason, username } = params
  return request({
    url: '/api/wxzp/saveZzInfo',
    method: 'get',
    params: { autoid, checktype, confirmdate, checkreason, username }
  })
}

// 检查是否完成面试测评
export function checkIvQtRes (params) {
  return request({
    url: '/api/wxzp/checkIvQtRes',
    method: 'get',
    params: params
  })
}

// 获取面试测评提交后的结果信息
export function getIvQtResList (params) {
  return request({
    url: '/api/wxzp/getIvQtResList',
    method: 'get',
    params: params
  })
}